@import url("https://fonts.googleapis.com/css?family=Muli:900,700,400,600,200,800");
@font-face {
  font-family: "Muli-Medium";
  src: url("/fonts/Muli-Medium.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "Muli";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
button:focus {
  outline: none !important;
  box-shadow: none !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
