/* COMMON */
/* TEXT */
.common-big-title {
  font-weight: 900;
  font-style: normal;
  font-size: 60px;
  color: rgba(255, 255, 255, 1);
}
.common-title {
  font-weight: 700;
  font-size: 32px;
  letter-spacing: 1.28px;
  line-height: 40px;
}
.common-title-small {
  font-weight: 700;
  font-size: 24px;
  letter-spacing: 1.28px;
  line-height: 40px;
}
.common-paragraph {
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  color: rgba(42, 42, 42, 1);
  letter-spacing: 0.72px;
  line-height: 24px;
}

.common-paragraph a {
  font-weight: 700;
  font-style: normal;
  color: rgba(199, 0, 0, 1);
  text-decoration: underline;
}

.common-paragraph a:hover {
  color: #8c0000;
}
.common-number-large {
  font-weight: 900;
  font-style: normal;
  font-size: 100px;
  color: rgba(199, 0, 0, 1);
  letter-spacing: 4px;
  line-height: 70px;
}
.common-subtitle {
  font-size: 20px;
  color: rgba(67, 67, 67, 1);
  text-align: right;
  letter-spacing: 0.8px;
  line-height: 25px;
}
.common-link {
  font-weight: 700;
  font-style: normal;
  color: rgba(199, 0, 0, 1);
  text-decoration: none !important;
}
.common-title-extra-small {
  font-weight: 600;
  font-style: normal;
  font-size: 18px;
  color: rgba(42, 42, 42, 1);
  text-align: left;
  letter-spacing: 0.72px;
  line-height: 23px;
}

.common-link:hover {
  color: #8c0000;
}

.common-error {
  color: rgba(199, 0, 0, 1);
  font-weight: 600;
  font-style: normal;
  font-size: 12px;
  text-align: left;
  letter-spacing: 0.72px;
  line-height: 23px;
}
/* Buttons */
.main-button {
  padding: 0.8rem 1.2rem;
  border-radius: 0.1rem;
  font-weight: 700;
  font-style: normal;
  font-size: 22px;
  color: rgba(255, 255, 255, 1);
  text-align: left;
  line-height: 28px;
}
.main-button-inverse {
  padding: 0.8rem 1.2rem;
  border-radius: 0.1rem;
  font-weight: 700;
  font-style: normal;
  font-size: 22px;
  color: rgba(42, 42, 42, 1);
  text-align: left;
  line-height: 28px;
}
.share-button {
  padding: 0;
  vertical-align: middle;
  font-weight: 600;
  font-style: normal;
  font-size: 18px;
  color: rgba(199, 0, 0, 1);
  letter-spacing: 0.72px;
  line-height: 24px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.share-button:hover {
  color: #8c0000 !important;
  text-decoration: none !important;
}

.share-button:hover + #share-icon {
  display: none;
}
#share-icon-dark {
  display: none;
}
.share-button:hover ~ #share-icon-dark {
  display: initial;
}

#copy-tooltip {
  display: none;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  font-weight: 400;
  font-size: 14px;
  border-radius: 5px;
  padding: 2px;
  position: absolute;
  margin-top: 5px;
}
.share-button:hover ~ #copy-tooltip {
  display: initial;
}

/* CHECKBOX */
.common-checkbox button {
  width: 27px;
  height: 27px;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(42, 42, 42, 1);
  background-color: transparent;
}

.common-checkbox span {
  font-weight: 300;
  font-style: normal;
  font-size: 18px;
  color: rgba(42, 42, 42, 1);
  text-align: left;
  letter-spacing: 0.72px;
  line-height: 23px;
}

/* TRANSITION ANIM */
.example-appear {
  opacity: 0.01;
}

.example-appear.example-appear-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
/* STRIPE */
.StripeElement {
  padding-bottom: 10px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #ced4da;
}

.StripeElement--focus {
  outline: none !important;
  box-shadow: none;
  border-bottom: 1px solid #c80000;
}

/*SHARE */
.share-container {
  position: absolute;
  background-color: white;
  float: right;
  display: inline;
  margin-left: 40px;
  z-index: 1;
}
.share-container .share-box {
  background-color: white;
  z-index: 1;
  margin-top: -10px;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  color: rgba(42, 42, 42, 1);
  text-align: left;
  letter-spacing: 0.72px;
  line-height: 23px;
  vertical-align: middle;
  padding: 20px;
}

.share-container .share-box-arrow {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  position: absolute;
  margin-left: -30px;
  margin-top: -5px;
  border-right: 10px solid white;
}

.share-container .fab {
  color: #c80000;
  margin-left: 12px;
  font-size: 23px;
}
.share-container .fab:hover {
  color: #8c0000;
  cursor: pointer;
}
.share-container .address {
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  color: #2a2a2a;
}

@media only screen and (max-width: 768px) {
  .common-big-title {
    font-size: 36px;
  }
  .common-number-large {
    font-size: 80px;
    line-height: 80px;
  }
  .share-container {
    float: left;
    left: 0;
    right: 0;
    margin-top: 50px;
    margin-left: 0px;
  }
  .share-container .share-box-arrow {
    margin-left: 0px;
    margin-top: -35px;
    transform: rotate(90deg);
  }
}
