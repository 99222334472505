.donate-form .maple-image {
  position: absolute;
  width: 200px;
  height: auto;
  transform: scaleX(-1);
  bottom: 0;
  margin-left: -50px;
  margin-bottom: -60px;
  z-index: -1;
  opacity: 0.5;
}
.donate-form .common-title {
  color: #c80000 !important;
}
.donate-form .btn-group {
  width: 100%;
}
.donate-form .btn-group button {
  font-weight: 300;
  font-style: normal;
  font-size: 22px;
  color: rgba(42, 42, 42, 1);
  text-align: left;
  letter-spacing: 0.88px;
  line-height: 28px;
  white-space: nowrap;
  padding-bottom: 13px;
  padding-left: 0;
  padding-right: 0;
}

.donate-form .btn-group button:focus {
  outline: none !important;
  box-shadow: none;
}

.donate-form .btn-group .active {
  font-weight: 700;
  font-style: normal;
  color: rgba(199, 0, 0, 1);
  text-align: left;
  letter-spacing: 0.88px;
  line-height: 28px;
  border-bottom: 1px solid #c80000;
  border-radius: 0;
}
.donate-form .form-wrapper {
  min-height: 100vh;
}
.donate-form .petition-section {
  margin-top: 10%;
}
.donate-form label {
  margin-bottom: 0;
  font-weight: 600;
  font-style: normal;
  font-size: 12px;
  color: rgba(199, 0, 0, 1);
  text-align: left;
  letter-spacing: 0.48px;
  line-height: 15px;
  opacity: 0;
  height: 0;
  position: absolute;
  transform-origin: left top;
  transform: translate(0px, -30px) scale(1.2);
  transition: all 200ms ease-in;
  pointer-events: none;
}
.donate-form input:focus ~ #input-lable {
  opacity: 1;
  transform: translate(0px, -55px) scale(1);
}
.donate-form input:not(:placeholder-shown) ~ #input-lable {
  opacity: 1 !important;
  transform: translate(0px, -55px) scale(1) !important;
}
.donate-form input {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}
.donate-form ::placeholder {
  font-weight: 300;
  font-style: normal;
  font-size: 16px;
  color: rgba(42, 42, 42, 1);
  text-align: left;
  letter-spacing: 0.64px;
  line-height: 20px;
  transition: all 200ms ease-in;
  opacity: 1;
}
.donate-form input:focus {
  outline: none !important;
  box-shadow: none;
  border-bottom: 1px solid #c80000;
}
.donate-form input:focus::placeholder {
  opacity: 0;
}
/* Switch */
.switch {
  position: relative;
  display: inline-block;
  width: 37px;
  height: 19px !important;
  opacity: 1 !important;
  transform: translate(0px, 3px) scale(1) !important;
  transition: none !important;
  pointer-events: all !important;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  border-color: #c80000;
  border-style: solid;
  border-width: 1px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 1px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  /* border-color: #c80000;
  background-color: #c80000;
  border-width: 2px; */
}

input:focus + .slider {
  box-shadow: 0 0 1px #c80000;
}

input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
  background-color: #c80000;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
  border-color: #c80000;
  border-style: solid;
  border-width: 1px;
}

/* CRYPTO */
.crypto-address {
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  color: #2a2a2a;
  overflow-wrap: break-word;
}
.crypto-payments .btn-group {
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-top: 10px !important;
  background-color: transparent !important;
}
.crypto-payments .btn-group button {
  background-color: transparent !important;
  border: none;
  font-weight: 300;
  font-style: normal;
  font-size: 15px;
  color: rgba(42, 42, 42, 1);
  text-align: center !important;
  letter-spacing: 0.88px;
  line-height: 28px;
  white-space: nowrap;
  padding-bottom: 5px;
  padding-left: 0;
  padding-right: 0;
}
.crypto-payments .btn-group .active {
  font-weight: 400;
  font-style: normal;
  text-align: left;
  letter-spacing: 0.88px;
  line-height: 28px;
  background-color: transparent !important;
  border-bottom: 1px solid #c80000 !important;
  border-radius: 0;
  color: rgba(42, 42, 42, 1) !important;
}
.crypto-payments .dropdown .dropdown-toggle {
  border-bottom: 1px solid #2a2a2a;
  width: 100%;
  text-align: left;
  border-radius: 0;
  margin-left: 0;
  padding-left: 0;
}
.crypto-payments .dropdown .dropdown-toggle::after {
  float: right;
  margin-top: 8px;
}

@media only screen and (max-width: 768px) {
  .donate-form .maple-image {
    position: absolute;
    width: 200px;
    height: auto;
    transform: scaleX(-1);
    margin-bottom: 0px;
    margin-top: 35%;
    top: 0;
    z-index: -1;
    opacity: 0.5;
    display: none;
  }
  .donate-form .petition-section {
    margin-top: 20px;
  }
  .donate-form .btn-group button {
    font-size: 14px;
  }
  .donate-form .btn-group {
    margin-top: 30px;
    margin-left: 20px;
  }
  .donate-form .form-wrapper {
    margin-left: 25px;
    margin-right: 25px;
  }
}
