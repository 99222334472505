@import url(https://fonts.googleapis.com/css?family=Muli:900,700,400,600,200,800);
@font-face {
  font-family: "Muli-Medium";
  src: url("/fonts/Muli-Medium.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "Muli";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
button:focus {
  outline: none !important;
  box-shadow: none !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* COMMON */
/* TEXT */
.common-big-title {
  font-weight: 900;
  font-style: normal;
  font-size: 60px;
  color: rgba(255, 255, 255, 1);
}
.common-title {
  font-weight: 700;
  font-size: 32px;
  letter-spacing: 1.28px;
  line-height: 40px;
}
.common-title-small {
  font-weight: 700;
  font-size: 24px;
  letter-spacing: 1.28px;
  line-height: 40px;
}
.common-paragraph {
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  color: rgba(42, 42, 42, 1);
  letter-spacing: 0.72px;
  line-height: 24px;
}

.common-paragraph a {
  font-weight: 700;
  font-style: normal;
  color: rgba(199, 0, 0, 1);
  text-decoration: underline;
}

.common-paragraph a:hover {
  color: #8c0000;
}
.common-number-large {
  font-weight: 900;
  font-style: normal;
  font-size: 100px;
  color: rgba(199, 0, 0, 1);
  letter-spacing: 4px;
  line-height: 70px;
}
.common-subtitle {
  font-size: 20px;
  color: rgba(67, 67, 67, 1);
  text-align: right;
  letter-spacing: 0.8px;
  line-height: 25px;
}
.common-link {
  font-weight: 700;
  font-style: normal;
  color: rgba(199, 0, 0, 1);
  text-decoration: none !important;
}
.common-title-extra-small {
  font-weight: 600;
  font-style: normal;
  font-size: 18px;
  color: rgba(42, 42, 42, 1);
  text-align: left;
  letter-spacing: 0.72px;
  line-height: 23px;
}

.common-link:hover {
  color: #8c0000;
}

.common-error {
  color: rgba(199, 0, 0, 1);
  font-weight: 600;
  font-style: normal;
  font-size: 12px;
  text-align: left;
  letter-spacing: 0.72px;
  line-height: 23px;
}
/* Buttons */
.main-button {
  padding: 0.8rem 1.2rem;
  border-radius: 0.1rem;
  font-weight: 700;
  font-style: normal;
  font-size: 22px;
  color: rgba(255, 255, 255, 1);
  text-align: left;
  line-height: 28px;
}
.main-button-inverse {
  padding: 0.8rem 1.2rem;
  border-radius: 0.1rem;
  font-weight: 700;
  font-style: normal;
  font-size: 22px;
  color: rgba(42, 42, 42, 1);
  text-align: left;
  line-height: 28px;
}
.share-button {
  padding: 0;
  vertical-align: middle;
  font-weight: 600;
  font-style: normal;
  font-size: 18px;
  color: rgba(199, 0, 0, 1);
  letter-spacing: 0.72px;
  line-height: 24px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.share-button:hover {
  color: #8c0000 !important;
  text-decoration: none !important;
}

.share-button:hover + #share-icon {
  display: none;
}
#share-icon-dark {
  display: none;
}
.share-button:hover ~ #share-icon-dark {
  display: inline;
  display: initial;
}

#copy-tooltip {
  display: none;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  font-weight: 400;
  font-size: 14px;
  border-radius: 5px;
  padding: 2px;
  position: absolute;
  margin-top: 5px;
}
.share-button:hover ~ #copy-tooltip {
  display: inline;
  display: initial;
}

/* CHECKBOX */
.common-checkbox button {
  width: 27px;
  height: 27px;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(42, 42, 42, 1);
  background-color: transparent;
}

.common-checkbox span {
  font-weight: 300;
  font-style: normal;
  font-size: 18px;
  color: rgba(42, 42, 42, 1);
  text-align: left;
  letter-spacing: 0.72px;
  line-height: 23px;
}

/* TRANSITION ANIM */
.example-appear {
  opacity: 0.01;
}

.example-appear.example-appear-active {
  opacity: 1;
  -webkit-transition: opacity 500ms ease-in;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  -webkit-transition: opacity 300ms ease-in;
  transition: opacity 300ms ease-in;
}
/* STRIPE */
.StripeElement {
  padding-bottom: 10px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #ced4da;
}

.StripeElement--focus {
  outline: none !important;
  box-shadow: none;
  border-bottom: 1px solid #c80000;
}

/*SHARE */
.share-container {
  position: absolute;
  background-color: white;
  float: right;
  display: inline;
  margin-left: 40px;
  z-index: 1;
}
.share-container .share-box {
  background-color: white;
  z-index: 1;
  margin-top: -10px;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  color: rgba(42, 42, 42, 1);
  text-align: left;
  letter-spacing: 0.72px;
  line-height: 23px;
  vertical-align: middle;
  padding: 20px;
}

.share-container .share-box-arrow {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  position: absolute;
  margin-left: -30px;
  margin-top: -5px;
  border-right: 10px solid white;
}

.share-container .fab {
  color: #c80000;
  margin-left: 12px;
  font-size: 23px;
}
.share-container .fab:hover {
  color: #8c0000;
  cursor: pointer;
}
.share-container .address {
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  color: #2a2a2a;
}

@media only screen and (max-width: 768px) {
  .common-big-title {
    font-size: 36px;
  }
  .common-number-large {
    font-size: 80px;
    line-height: 80px;
  }
  .share-container {
    float: left;
    left: 0;
    right: 0;
    margin-top: 50px;
    margin-left: 0px;
  }
  .share-container .share-box-arrow {
    margin-left: 0px;
    margin-top: -35px;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
}

/* TOP SECTION */
.hero {
  background-color: #f5f5f5;
  padding: 40px 50px 40px 0;
}
.hero .text-container-wrapper {
  padding-top: 8%;
  padding-bottom: 8%;
  z-index: 1;
  position: relative;
}
.hero .text-container {
  background-color: #c80000;
  height: 100%;
  padding-left: 15%;
  padding-right: 15%;
  -webkit-transform: translateX(100px);
          transform: translateX(100px);
  z-index: 1;
  position: relative;
}
/* STORY SECTION */

.story-section {
  padding: 100px 100px 100px 100px;
}
.maple-image {
  height: 100vh;
  -webkit-transform: translateX(100px);
          transform: translateX(100px);
}
/* Recent Donations */
.section-item {
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}
.section-item:hover {
  -webkit-transform: translateX(15px);
          transform: translateX(15px);
  color: #8c0000;
}
.section-item img {
  margin-left: 10px;
  vertical-align: baseline;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.section-item:hover img {
  -webkit-transform: translate(15px, 0px);
          transform: translate(15px, 0px);
  opacity: 1;
}
.section-item button:hover {
  color: #8c0000;
}
.section-item button {
  padding-left: 0;
  font-weight: 600;
  font-style: normal;
  font-size: 18px;
  color: rgba(199, 0, 0, 1);
  text-align: left;
  letter-spacing: 0.72px;
  line-height: 24px;
  text-decoration: none;
}
.total-container {
  text-align: right;
}
/* DONATION CARD */
.card {
  border-color: transparent;
}
.card .person {
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  color: rgba(42, 42, 42, 1);
  text-align: left;
  letter-spacing: 0.72px;
  line-height: 24px;
}
.card .money {
  font-weight: 800;
  font-style: normal;
  font-size: 24px;
  color: rgba(199, 0, 0, 1);
  text-align: left;
  letter-spacing: 0.96px;
  line-height: 24px;
}
.card .date {
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  color: rgba(128, 127, 127, 1);
  text-align: right;
  letter-spacing: 0.72px;
  line-height: 24px;
}

/* SOCIAL CARD */
.social-comment .comment {
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  color: rgba(42, 42, 42, 1);
  text-align: left;
  letter-spacing: 0.72px;
  line-height: 24px;
}
.social-comment .from {
  font-weight: 600;
  font-style: normal;
  font-size: 18px;
  color: rgba(42, 42, 42, 1);
  text-align: left;
  letter-spacing: 0.72px;
}
.social-comment .platform {
  font-weight: 200;
  font-style: italic;
  color: rgba(42, 42, 42, 1);
  text-align: left;
  letter-spacing: 0.72px;
  line-height: 24px;
  text-decoration: none;
}
.social-comment .platform a {
  text-decoration: none;
  color: rgba(42, 42, 42, 1);
}
.social-comment .full-size-image {
  width: 90px;
  height: 90px;
}
.social-comment .mobile-size-image {
  display: none;
}
/* VIDEO */
.video-section {
  background-color: #f5f5f5;
}
.video-section .video-container {
  text-align: center;
  position: relative;
}
.video-section video {
  width: 100%;
}
.video-section .play-button-container {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.video-section .play-button {
  width: 100px;
  height: 100px;
  background-color: transparent;
  border: none;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 1;
}
.video-container .playing-fade {
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transition: all 1s ease-in;
  transition: all 1s ease-in;
  opacity: 0;
}

.video-container .playing-fade:hover {
  opacity: 1;
}
.video-section .help-button {
  margin-top: -50px;
  z-index: 2;
  position: relative;
}
.video-divider {
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  justify-content: center;
  align-items: center;
  margin-top: -10px;
}
.video-divider .video-circle {
  width: 10px;
  height: 10px;
  background-color: #dc3545;
  border-radius: 25px;
  margin-top: 20px;
}
.video-divider :first-child {
  margin-top: 0px;
}
.video-divider :last-child {
  margin-bottom: 20px;
}

/* GOAL-FUNDS*/
.card-goal {
  min-height: 260px;
}
.red-container {
  background-color: #c80000;
  color: white !important;
}

.red-container .common-paragraph {
  color: white !important;
}
.red-container ul li {
  color: rgba(255, 255, 255, 1) !important;
}

.goal-funds-section ul li {
  margin-top: 20px;
  margin-left: -20px;
  font-weight: 600;
  font-style: normal;
  font-size: 18px;
  color: rgba(42, 42, 42, 1);
  text-align: left;
  letter-spacing: 0.72px;
  line-height: 24px;
}
.goal-funds-section .white-container ul {
  list-style: none;
}
.goal-funds-section .white-container ul li::before {
  content: "\2022";
  color: red;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
/* MODAL*/

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: white;
  z-index: 9999;
}

.slide-enter {
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}

.slide-enter.slide-enter-active {
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
  -webkit-transition: -webkit-transform 500ms ease-in;
  transition: -webkit-transform 500ms ease-in;
  transition: transform 500ms ease-in;
  transition: transform 500ms ease-in, -webkit-transform 500ms ease-in;
}

.slide-leave {
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
}

.slide-leave.slide-leave-active {
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  -webkit-transition: -webkit-transform 500ms ease-in;
  transition: -webkit-transform 500ms ease-in;
  transition: transform 500ms ease-in;
  transition: transform 500ms ease-in, -webkit-transform 500ms ease-in;
}
@media only screen and (max-width: 768px) {
  .story-section {
    padding: 50px 60px 50px 60px;
    text-align: center;
  }
  .maple-image {
    display: none;
  }
  .section-item img {
    position: absolute;
    margin-top: 4px;
  }
  .social-comment .comment {
    margin: 0 !important;
  }
  .social-comment .full-size-image {
    display: none;
  }
  .social-comment .mobile-size-image {
    display: block;
    width: 90px;
    height: 90px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
  }
  .slide-enter {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }

  .slide-enter.slide-enter-active {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transition: -webkit-transform 500ms ease-in;
    transition: -webkit-transform 500ms ease-in;
    transition: transform 500ms ease-in;
    transition: transform 500ms ease-in, -webkit-transform 500ms ease-in;
  }

  .slide-leave {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }

  .slide-leave.slide-leave-active {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    -webkit-transition: -webkit-transform 500ms ease-in;
    transition: -webkit-transform 500ms ease-in;
    transition: transform 500ms ease-in;
    transition: transform 500ms ease-in, -webkit-transform 500ms ease-in;
  }
  .modal-close {
    float: right;
    margin: 10px 10px;
  }
  .modal-container {
    padding: 0 !important;
  }
  .video-section .text-right {
    text-align: inherit !important;
  }
  .video-section .help-button {
    margin-top: 40px;
    z-index: auto;
    z-index: initial;
    position: static;
    position: initial;
    margin-left: auto;
  }
  .total-container {
    text-align: center;
  }
  .hero .text-container-wrapper {
    margin: 0;
    padding: 0 12px 0 12px;
  }
  .hero .text-container {
    -webkit-transform: translate(0, -90px);
            transform: translate(0, -90px);
    padding: 10%;
  }
  .hero .img-container-wrapper {
    margin: 0;
    padding: 0;
  }
  .hero .text-container .white-maple-image {
    width: 50px;
    height: 50px;
  }
  .hero {
    padding: 0;
    margin-bottom: -50px;
  }
  .hero .row {
    margin: 0;
    padding: 20px 15px 20px 15px !important;
  }
  .card-goal {
    text-align: left;
  }
}

.donate-form .maple-image {
  position: absolute;
  width: 200px;
  height: auto;
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
  bottom: 0;
  margin-left: -50px;
  margin-bottom: -60px;
  z-index: -1;
  opacity: 0.5;
}
.donate-form .common-title {
  color: #c80000 !important;
}
.donate-form .btn-group {
  width: 100%;
}
.donate-form .btn-group button {
  font-weight: 300;
  font-style: normal;
  font-size: 22px;
  color: rgba(42, 42, 42, 1);
  text-align: left;
  letter-spacing: 0.88px;
  line-height: 28px;
  white-space: nowrap;
  padding-bottom: 13px;
  padding-left: 0;
  padding-right: 0;
}

.donate-form .btn-group button:focus {
  outline: none !important;
  box-shadow: none;
}

.donate-form .btn-group .active {
  font-weight: 700;
  font-style: normal;
  color: rgba(199, 0, 0, 1);
  text-align: left;
  letter-spacing: 0.88px;
  line-height: 28px;
  border-bottom: 1px solid #c80000;
  border-radius: 0;
}
.donate-form .form-wrapper {
  min-height: 100vh;
}
.donate-form .petition-section {
  margin-top: 10%;
}
.donate-form label {
  margin-bottom: 0;
  font-weight: 600;
  font-style: normal;
  font-size: 12px;
  color: rgba(199, 0, 0, 1);
  text-align: left;
  letter-spacing: 0.48px;
  line-height: 15px;
  opacity: 0;
  height: 0;
  position: absolute;
  -webkit-transform-origin: left top;
          transform-origin: left top;
  -webkit-transform: translate(0px, -30px) scale(1.2);
          transform: translate(0px, -30px) scale(1.2);
  -webkit-transition: all 200ms ease-in;
  transition: all 200ms ease-in;
  pointer-events: none;
}
.donate-form input:focus ~ #input-lable {
  opacity: 1;
  -webkit-transform: translate(0px, -55px) scale(1);
          transform: translate(0px, -55px) scale(1);
}
.donate-form input:not(:placeholder-shown) ~ #input-lable {
  opacity: 1 !important;
  -webkit-transform: translate(0px, -55px) scale(1) !important;
          transform: translate(0px, -55px) scale(1) !important;
}
.donate-form input {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}
.donate-form ::-webkit-input-placeholder {
  font-weight: 300;
  font-style: normal;
  font-size: 16px;
  color: rgba(42, 42, 42, 1);
  text-align: left;
  letter-spacing: 0.64px;
  line-height: 20px;
  -webkit-transition: all 200ms ease-in;
  transition: all 200ms ease-in;
  opacity: 1;
}
.donate-form :-ms-input-placeholder {
  font-weight: 300;
  font-style: normal;
  font-size: 16px;
  color: rgba(42, 42, 42, 1);
  text-align: left;
  letter-spacing: 0.64px;
  line-height: 20px;
  -ms-transition: all 200ms ease-in;
  transition: all 200ms ease-in;
  opacity: 1;
}
.donate-form ::-ms-input-placeholder {
  font-weight: 300;
  font-style: normal;
  font-size: 16px;
  color: rgba(42, 42, 42, 1);
  text-align: left;
  letter-spacing: 0.64px;
  line-height: 20px;
  -ms-transition: all 200ms ease-in;
  transition: all 200ms ease-in;
  opacity: 1;
}
.donate-form ::placeholder {
  font-weight: 300;
  font-style: normal;
  font-size: 16px;
  color: rgba(42, 42, 42, 1);
  text-align: left;
  letter-spacing: 0.64px;
  line-height: 20px;
  -webkit-transition: all 200ms ease-in;
  transition: all 200ms ease-in;
  opacity: 1;
}
.donate-form input:focus {
  outline: none !important;
  box-shadow: none;
  border-bottom: 1px solid #c80000;
}
.donate-form input:focus::-webkit-input-placeholder {
  opacity: 0;
}
.donate-form input:focus:-ms-input-placeholder {
  opacity: 0;
}
.donate-form input:focus::-ms-input-placeholder {
  opacity: 0;
}
.donate-form input:focus::placeholder {
  opacity: 0;
}
/* Switch */
.switch {
  position: relative;
  display: inline-block;
  width: 37px;
  height: 19px !important;
  opacity: 1 !important;
  -webkit-transform: translate(0px, 3px) scale(1) !important;
          transform: translate(0px, 3px) scale(1) !important;
  -webkit-transition: none !important;
  transition: none !important;
  pointer-events: all !important;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  border-color: #c80000;
  border-style: solid;
  border-width: 1px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 1px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  /* border-color: #c80000;
  background-color: #c80000;
  border-width: 2px; */
}

input:focus + .slider {
  box-shadow: 0 0 1px #c80000;
}

input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  transform: translateX(18px);
  background-color: #c80000;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
  border-color: #c80000;
  border-style: solid;
  border-width: 1px;
}

/* CRYPTO */
.crypto-address {
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  color: #2a2a2a;
  overflow-wrap: break-word;
}
.crypto-payments .btn-group {
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-top: 10px !important;
  background-color: transparent !important;
}
.crypto-payments .btn-group button {
  background-color: transparent !important;
  border: none;
  font-weight: 300;
  font-style: normal;
  font-size: 15px;
  color: rgba(42, 42, 42, 1);
  text-align: center !important;
  letter-spacing: 0.88px;
  line-height: 28px;
  white-space: nowrap;
  padding-bottom: 5px;
  padding-left: 0;
  padding-right: 0;
}
.crypto-payments .btn-group .active {
  font-weight: 400;
  font-style: normal;
  text-align: left;
  letter-spacing: 0.88px;
  line-height: 28px;
  background-color: transparent !important;
  border-bottom: 1px solid #c80000 !important;
  border-radius: 0;
  color: rgba(42, 42, 42, 1) !important;
}
.crypto-payments .dropdown .dropdown-toggle {
  border-bottom: 1px solid #2a2a2a;
  width: 100%;
  text-align: left;
  border-radius: 0;
  margin-left: 0;
  padding-left: 0;
}
.crypto-payments .dropdown .dropdown-toggle::after {
  float: right;
  margin-top: 8px;
}

@media only screen and (max-width: 768px) {
  .donate-form .maple-image {
    position: absolute;
    width: 200px;
    height: auto;
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
    margin-bottom: 0px;
    margin-top: 35%;
    top: 0;
    z-index: -1;
    opacity: 0.5;
    display: none;
  }
  .donate-form .petition-section {
    margin-top: 20px;
  }
  .donate-form .btn-group button {
    font-size: 14px;
  }
  .donate-form .btn-group {
    margin-top: 30px;
    margin-left: 20px;
  }
  .donate-form .form-wrapper {
    margin-left: 25px;
    margin-right: 25px;
  }
}

