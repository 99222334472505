/* TOP SECTION */
.hero {
  background-color: #f5f5f5;
  padding: 40px 50px 40px 0;
}
.hero .text-container-wrapper {
  padding-top: 8%;
  padding-bottom: 8%;
  z-index: 1;
  position: relative;
}
.hero .text-container {
  background-color: #c80000;
  height: 100%;
  padding-left: 15%;
  padding-right: 15%;
  transform: translateX(100px);
  z-index: 1;
  position: relative;
}
/* STORY SECTION */

.story-section {
  padding: 100px 100px 100px 100px;
}
.maple-image {
  height: 100vh;
  transform: translateX(100px);
}
/* Recent Donations */
.section-item {
  transition: transform 0.3s ease-out;
}
.section-item:hover {
  transform: translateX(15px);
  color: #8c0000;
}
.section-item img {
  margin-left: 10px;
  vertical-align: baseline;
  transition: all 0.3s ease-out;
}
.section-item:hover img {
  transform: translate(15px, 0px);
  opacity: 1;
}
.section-item button:hover {
  color: #8c0000;
}
.section-item button {
  padding-left: 0;
  font-weight: 600;
  font-style: normal;
  font-size: 18px;
  color: rgba(199, 0, 0, 1);
  text-align: left;
  letter-spacing: 0.72px;
  line-height: 24px;
  text-decoration: none;
}
.total-container {
  text-align: right;
}
/* DONATION CARD */
.card {
  border-color: transparent;
}
.card .person {
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  color: rgba(42, 42, 42, 1);
  text-align: left;
  letter-spacing: 0.72px;
  line-height: 24px;
}
.card .money {
  font-weight: 800;
  font-style: normal;
  font-size: 24px;
  color: rgba(199, 0, 0, 1);
  text-align: left;
  letter-spacing: 0.96px;
  line-height: 24px;
}
.card .date {
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  color: rgba(128, 127, 127, 1);
  text-align: right;
  letter-spacing: 0.72px;
  line-height: 24px;
}

/* SOCIAL CARD */
.social-comment .comment {
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  color: rgba(42, 42, 42, 1);
  text-align: left;
  letter-spacing: 0.72px;
  line-height: 24px;
}
.social-comment .from {
  font-weight: 600;
  font-style: normal;
  font-size: 18px;
  color: rgba(42, 42, 42, 1);
  text-align: left;
  letter-spacing: 0.72px;
}
.social-comment .platform {
  font-weight: 200;
  font-style: italic;
  color: rgba(42, 42, 42, 1);
  text-align: left;
  letter-spacing: 0.72px;
  line-height: 24px;
  text-decoration: none;
}
.social-comment .platform a {
  text-decoration: none;
  color: rgba(42, 42, 42, 1);
}
.social-comment .full-size-image {
  width: 90px;
  height: 90px;
}
.social-comment .mobile-size-image {
  display: none;
}
/* VIDEO */
.video-section {
  background-color: #f5f5f5;
}
.video-section .video-container {
  text-align: center;
  position: relative;
}
.video-section video {
  width: 100%;
}
.video-section .play-button-container {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.video-section .play-button {
  width: 100px;
  height: 100px;
  background-color: transparent;
  border: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.video-container .playing-fade {
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 1s ease-in;
  opacity: 0;
}

.video-container .playing-fade:hover {
  opacity: 1;
}
.video-section .help-button {
  margin-top: -50px;
  z-index: 2;
  position: relative;
}
.video-divider {
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  justify-content: center;
  align-items: center;
  margin-top: -10px;
}
.video-divider .video-circle {
  width: 10px;
  height: 10px;
  background-color: #dc3545;
  border-radius: 25px;
  margin-top: 20px;
}
.video-divider :first-child {
  margin-top: 0px;
}
.video-divider :last-child {
  margin-bottom: 20px;
}

/* GOAL-FUNDS*/
.card-goal {
  min-height: 260px;
}
.red-container {
  background-color: #c80000;
  color: white !important;
}

.red-container .common-paragraph {
  color: white !important;
}
.red-container ul li {
  color: rgba(255, 255, 255, 1) !important;
}

.goal-funds-section ul li {
  margin-top: 20px;
  margin-left: -20px;
  font-weight: 600;
  font-style: normal;
  font-size: 18px;
  color: rgba(42, 42, 42, 1);
  text-align: left;
  letter-spacing: 0.72px;
  line-height: 24px;
}
.goal-funds-section .white-container ul {
  list-style: none;
}
.goal-funds-section .white-container ul li::before {
  content: "\2022";
  color: red;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
/* MODAL*/

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: white;
  z-index: 9999;
}

.slide-enter {
  transform: translateY(100%);
}

.slide-enter.slide-enter-active {
  transform: translateY(0%);
  transition: transform 500ms ease-in;
}

.slide-leave {
  transform: translateY(0%);
}

.slide-leave.slide-leave-active {
  transform: translateY(100%);
  transition: transform 500ms ease-in;
}
@media only screen and (max-width: 768px) {
  .story-section {
    padding: 50px 60px 50px 60px;
    text-align: center;
  }
  .maple-image {
    display: none;
  }
  .section-item img {
    position: absolute;
    margin-top: 4px;
  }
  .social-comment .comment {
    margin: 0 !important;
  }
  .social-comment .full-size-image {
    display: none;
  }
  .social-comment .mobile-size-image {
    display: block;
    width: 90px;
    height: 90px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
  }
  .slide-enter {
    transform: translateX(100%);
  }

  .slide-enter.slide-enter-active {
    transform: translateX(0%);
    transition: transform 500ms ease-in;
  }

  .slide-leave {
    transform: translateX(0%);
  }

  .slide-leave.slide-leave-active {
    transform: translateX(100%);
    transition: transform 500ms ease-in;
  }
  .modal-close {
    float: right;
    margin: 10px 10px;
  }
  .modal-container {
    padding: 0 !important;
  }
  .video-section .text-right {
    text-align: inherit !important;
  }
  .video-section .help-button {
    margin-top: 40px;
    z-index: initial;
    position: initial;
    margin-left: auto;
  }
  .total-container {
    text-align: center;
  }
  .hero .text-container-wrapper {
    margin: 0;
    padding: 0 12px 0 12px;
  }
  .hero .text-container {
    transform: translate(0, -90px);
    padding: 10%;
  }
  .hero .img-container-wrapper {
    margin: 0;
    padding: 0;
  }
  .hero .text-container .white-maple-image {
    width: 50px;
    height: 50px;
  }
  .hero {
    padding: 0;
    margin-bottom: -50px;
  }
  .hero .row {
    margin: 0;
    padding: 20px 15px 20px 15px !important;
  }
  .card-goal {
    text-align: left;
  }
}
